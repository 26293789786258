
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700&family=Lato:wght@700&family=Montserrat:wght@500&display=swap');


@import "react-alice-carousel/lib/alice-carousel.css";
.App {
  
  
  height: 100%;
  width: 100%;
}

.carousel-item{
  height: 100%;
}
.crslBackground{
  height: 30vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  
  border-radius: 20px;
  min-height: 200px;
}
.w-100{
  background-position: center bottom;
  background-size: cover;
  background-repeat: none;
}

.carousel-indicators [data-bs-target] {
background-color: #E47100 !important;

}
.carousel-control-next-icon, .carousel-control-prev-icon {

  background-color: black !important;
  border-radius: 50px;
 
}
.w-100{
height: 100% !important;
}
 .crslAr .carousel-control-next {

left: 0 !important;
right: auto !important;
transform: rotate(180deg) !important;
}
.crslAr .carousel-control-prev {
right: 0 !important;
transform: rotate(180deg) !important;
}

.carousel-control-next-icon {
padding: 1.2em !important;
}
.carousel-control-prev-icon {

  background-size: 60% !important;
}

.carousel-control-prev-icon {
  padding: 1.2em !important;
  }
  .carousel-control-next-icon{
  
    background-size: 60% !important;
  }
@media screen and (max-width: 888px){
  
     
  }

  @media screen and (max-width: 647px){
   }
    @media screen and (max-width: 647px){
     }