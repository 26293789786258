.mudiaf-bar {
    background-color: #e47100;
    overflow-x: scroll;
    width: 100%;
 
    position: relative;
    white-space: nowrap;
}
.loader{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.orderLoader{
transform: rotate(90deg);

}
.mudiaf-items-active {
    background-color: #fff;
    color: #e47100 !important;
   }
.mudiaf-items {
    font-family: 'Cairo',sans-serif;
    font-style: normal;
    font-weight: 700;
    display: inline-block;
    width: 20%;
    min-width: 200px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    white-space: nowrap;
    color: white;
    cursor: pointer;
}
.mudiaf-items-active a, .mudiaf-items-active a:hover {
    color: #e47100 !important;
}

.mudiaf-items a, .mudiaf-items a:hover {
    color: #fff;
    font-family: GE_SS_Bold;
}
a {
 
    text-decoration: none !important;
    background-color: transparent;
}


    .hospitableInfo {
    
       
        margin: 10px 0;
    }
.hospitableInfo h1 {
    color: #e47100;
    font-family: Cairo;
    font-weight: 700;
    font-size: 22px;
 
    margin: 0;
    margin-top: 50px;
    margin-bottom: 1em;
}
.hospitableInfo p {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    letter-spacing: -0.3px;
    
    color: #4F4F4F;
border-radius: 12px;
background: #F4F4F4;
padding: 2em;

}  
.medyafMenu .bckg{
    width: 100%;
    border-radius: 8px;
    height: 10em;
    background-size: cover;
    background-repeat: no-repeat;
background-position: top center;
}
.zoom {

    transition: transform .2s; /* Animation */
z-index: 10;
  }
  .react-datepicker__input-container{


display: contents !important;
  }
  
  .zoom:hover > label {

color: red !important;
  }
  .zoom:hover {
    transform: scale(2.2);
transform-origin: 0 50%;
background-size: contain;
}
.zoomAr:hover{
    transform-origin: 100% 50%; 
}

.menuContainer{

    background: #FFFFFF;
box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
border-radius: 8px;
padding:1em 1em;

}



.medyafMenu {
   text-align: center;
   background: white;
border-radius: 12px;
min-width: 236px;


}
.reccomendation {
 
 box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
 padding: 1em;
 padding-bottom: 0;

 }

.medyafMenu button{
    background: #E47100;
    border-radius: 10px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;
color: #FFFFFF;
border: none;
width: 100%;
padding: 0.2em;

 }
 .paddingMenu{
     padding: 1em 0;
     padding-bottom: 0;
 }

 .medyafMenu button:hover{
    background: #f1861c;}


 .medyafMenu p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
  
    letter-spacing: -0.3px;
  
    /* #331900 */
    
    color: #E47100;
 }
 .medyafDetails p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.3px;
 
    /* Gray 3 */
    
    color: #828282;
   
 }
 .details p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    letter-spacing: -0.3px;
 
  
    
    color: #828282;
   
 }

 .mmIcon{
    color: #DB095B;;  
    margin-right:  0.3em;  
}


.mmIconsAr{
    color: #DB095B;  
    margin-left:  0.3em;  
}
.mmIcon2{
    color: #DB095B;  
    margin-right:  0.5em;  
}


.mmIconsAr2{
    color: #DB095B;  
    margin-left:  0.5em;  
}

 .medyafDetails{
     display: flex;
     justify-content: space-between;
 }
 .details{
    display: flex;
    justify-content: space-between;
} 
 /*services*/
 
.servicesTitle{

    color: #e47100;
    font-family: 'Cairo';
    font-weight: 700;
    font-size: 22px;
  
  
margin-bottom: 1.2em;
}
 .additional{
 
 
     background: white;
  border-radius: 12px;

} 

.additional img{
    width: 100%;
    border-radius: 12px 12px 0 0;
}

.serviceImage{
background-size: cover;
width: 100%;




border-radius: 8px;
height: 10em;
background-size: cover;
background-repeat: no-repeat;
background-position: top center;
}

.additional p{
    /*  font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
  
    letter-spacing: -0.3px;
  
    /* #331900 
    
    color: #E47100;
    margin:auto !important;*/
   
 }

 .serviceName{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #633235;

 }

 .serviceDetail{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #633235;
width: 100%;

 }
 .medyafServices p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
 
    letter-spacing: -0.3px;
    
  margin-bottom: 0;
    
    color: #828282;
   
 }
 .goBasket{
     position: relative;
 }

 .count:hover::before{

    background-color: white;
    color: #e47100;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
 }
 .count::before{

content: attr(data-count);
position: absolute;
top:-0.5em;
left: -0.5em;
width:1.5em;
height: 1.5em;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background-color: #e47100;
color: white;
 }

 .copyRights{
    padding: 1.5em 0;
align-items: center;
    display: flex;
    justify-content: space-between;
}
.footerLink{
    color:#E47100 !important
   }
.copyRights p{
    margin-bottom: 0;
   font-weight: 600;
   font-size: 14px;
}
.socials svg{
    color: white;

}
.socials a{
margin-bottom: 15px;
cursor: pointer;
}
.socials a:hover svg{
    opacity: 0.8;

   
}
.socials svg{
    width: 23px;
    height: 23px;
}
.socials{
    position: fixed;
    left: 0;
    border-radius:0 6px 6px 0;
    top: 35%;
    z-index: 12;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #6c2631;
}
.socialsEn{
    left: auto !important;
    right: 0px;
    border-radius: 6px 0 0 6px  !important;

 
}
 .innerNav1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:0.3em;
    padding-bottom:0.3em;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.3px;
    margin-top: 1em;
    color: #4F4F4F;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

 }
 .innerNav1 p{
    margin-bottom: 0;
 }
.itemName{
    color: #E47100;
}
.innerNav1 button{

        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        
        text-align: right;
        letter-spacing: -0.3px;
        color: white;
        background: #e47100;
        border-radius: 10px;
    
        padding: 0.5em 3em;
    border: none;
  
}
.innerNav1 button:hover{
    background: #eb7a09;
}
.itemPrice{
    display: inline;
    color: #E47100;
    margin-right: 10px;
}
.itemPriceAr{
    display: inline;
    color: #E47100;
    margin-left: 10px;
}
.otherMenus .other{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 70px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: -0.3px;
color: #E47100;

margin-left: 1em;

}

 .btnsAr .swiper-button-prev {
        background-image: url("../Images/arrow-right.svg") !important;
        background-repeat: no-repeat;
        background-color: rgba(106, 38, 38, 0.476);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
        background-position: center;
        border-radius: 50%;
        padding: 1em 1.3em;
      }
      .btnsAr  .swiper-button-prev:hover {
     
        background-color: rgba(106, 38, 38, 1);
       
      }
      .btnsAr   .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: '' !important;
    }
      
    .btnsAr .swiper-button-next {
        background-image: url("../Images/arrow-left.svg") !important;
     
        background-repeat: no-repeat;
        background-color: rgba(106, 38, 38, 0.476);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
        background-position: center;
        border-radius: 50%;
        padding: 1em 1.3em;
        
      }
      .btnsAr .swiper-button-next:hover {
     
        background-color: rgba(106, 38, 38, 1);
       
      }
      .btnsAr .swiper-button-next:after, .swiper-rtl .swiper-button-next:after {
        content: '' !important;
    }
    .btnsEn .swiper-button-prev {
        background-image: url("../Images/arrow-left.svg") !important;
        background-repeat: no-repeat;
        background-color: rgba(106, 38, 38, 0.476);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
        background-position: center;
        border-radius: 50%;
        padding: 1em 1.3em;
      }
      .btnsEn  .swiper-button-prev:hover {
     
        background-color: rgba(106, 38, 38, 1);
       
      }
      .btnsEn   .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: '' !important;
    }
      
    .btnsEn .swiper-button-next {
        background-image: url("../Images/arrow-right.svg") !important;
     
        background-repeat: no-repeat;
        background-color: rgba(106, 38, 38, 0.476);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
        background-position: center;
        border-radius: 50%;
        padding: 1em 1.3em;
        
      }
      .btnsEn .swiper-button-next:hover {
     
        background-color: rgba(106, 38, 38, 1);
       
      }
      .btnsEn .swiper-button-next:after, .swiper-rtl .swiper-button-next:after {
        content: '' !important;
    }
.otherMenus{
    margin-top: 2em;
    margin-bottom: 5em ;
}
.otherMenusAr{
    margin-top: 2em;
    margin-bottom: 5em ;
}
.otherMenusAr .other{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 70px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: -0.3px;
color: #E47100;

margin-right: 1em;
}
.medyafDetails span{
    color: #331900;
    
}
 /* Contact US */
 .callIcon{

    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
 }
 .contact{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 60px;
    
    letter-spacing: -0.3px;
    
    /* #E47100 */
    
    color: #E47100;
 }

 .phones{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;

    letter-spacing: -0.3px;
  color: #331900;
  display: flex;
  justify-content: space-between;
 }
.phones a{
    color:  #331900 !important;
}

 .socials .socialIcon{
     margin-right: 50px;
 }
 .socialsAr .socialIcon{
    margin-left: 50px;
    margin-right: 0;
}
 .socials  a{
     color: #331900 !important;
 }
 .socials a:hover{
    color: #e47100 !important;
}


.cuHeader{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 30px;

letter-spacing: -0.3px;

color: #3F3F3F;
margin: 2em 0;

}
.cuTitles{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 49px;
 
    letter-spacing: -0.3px;
    
    color: #633235;
}
.orderData{
    overflow: auto;
 
  
}
th{
    padding-bottom: 1.2em !important;
  }
  td{
   
  }
  
.table{
    min-width: 600px;
}

.contactForm{
    background: #F4F4F4;
border-radius: 12px;
padding: 1em 2em;
margin-bottom: 2em;
width: 90%;
}
.contactForm span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;
}
.invalidSpan{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;

}
.contactForm label{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 41px;

    letter-spacing: -0.3px;
    display: block;
    color: #4F4F4F;
margin-bottom: 0.2em;
margin-top: 1em;
}
.contactForm input{
    background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
border: none;
color: #ACACAC;
padding: 0.5em;
width: 100%;
}
 .fluid{
    --bs-gutter-x: 3rem;

}
.contactForm textarea{
    background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
border: none;
color: #ACACAC;
padding: 0.5em;
width: 100%;
}
.contactForm button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;
    color: white;
    background: #e47100;
    border-radius: 10px;

    padding: 0.5em 3em;
border: none;

}
.contactForm button:hover{
    background: #f08215;

}
.contactPhone p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 45px;

letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0;
}
.contactPhone div{
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 2.5em;
}
.contactPhone button{
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 100px;
  padding:0.7em;
    }
    .contactPhone button:hover{
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
    }
    .contactSuccess{
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .cuSocials button{

        background: rgba(228, 113, 0, 0.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 100px;
        width: 60px;
        height: 60px;
    margin-right: 2em;
    text-align: center;
  padding: 0;
    }
    .cuSocials button:hover{
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);

    }

    .cuSocialsEn button{
        margin-left: 2em ;
margin-right: 0;
    }
    .twitter img{
      position: relative;
      top:3px;
    }
   .cuSocials{
    margin-top: 7em; 
   }

/* gallery */
.gallery{
    position: relative;
    display: flex;
    justify-content: center;
}
.gallery img{
    width: 100%;
    height: 300px;
}
.gallery div{
    text-align: center;
   position: absolute;
   bottom: 0;
   background-color: black;
   width: 100%;
   opacity: 50%;
   color: white;
   padding-top: 10px;
   cursor: pointer;
}
.addImage{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
/* identical to box height */

text-align: right;
letter-spacing: -0.3px;

/* #FFFFFF */

color: #FFFFFF;
background: #E47100;
border-radius: 10px;
border: none;
padding: 0.5em 5em;
margin-bottom: 2em;
}
.addImage:hover{
    background: #f38110;

}



.shareImage{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 60px;

    letter-spacing: -0.3px;
  color: #e47100;
}

.shareForm{ 
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    padding: 2em;
  
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);

}
.shareForm label{ 
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;

    letter-spacing: -0.3px;
    
    
    color: #633235;
display: block;
}

.shareForm input{ 
   
    margin-top: 25px;
    background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 12px;
padding: 1em 0em;
color: #633235;
cursor: pointer;
}
#pass{
    width: 100%;
  
}
#confirmPassword{
    width: 100%;
   
}
#pass1{
    width: 100%;
  
}

.shareForm input::-webkit-file-upload-button {
    visibility: hidden;
  }
.shareForm button{ 
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;
    
    border: 1px solid #E47100;
    color: #E47100;
    background: white;
    border-radius: 10px;

    padding: 0.5em 3em;
 display: block;
margin-top: 50px;
}

.shareForm button:hover{ 

    color: white;
    background-color: #e47100;
}
.imgIcon{
    color: #e47100;
    position: absolute;
    bottom: 18px;
    left: 25px;
}

  /* Party */

  .colFlex{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    
      text-align: left;
  }
  .colFlexAr{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  
    text-align: right;
}
  .colFlexBtn{
    display: flex;
    align-items: center;
    justify-content: end; 
  }
  body.active-modal {
    overflow-y: hidden;
}
.orderAdded p{
margin: 0 !important;

}
.orderAdded{
    height:  unset !important;
}
.orderAdded .modalName{
    margin-bottom: 0.5em !important;
}
.orderAdded .vCenter{
    transform: translateY(0)
}
.addedLogo{
    width: 150px !important;
    height: auto !important;
    margin-bottom: 1em;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.foodModal, .modalOverlay {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 11;
}

.modalOverlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 1em;
    border-radius: 20px;
  
    z-index: 12;

   display: unset;
width: 500px;
height: 571px;
}
.vCenter{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.modal-content img{
width: 100%;
    max-width: 300px;
    height: 300px;
}
.close-modal {
    position: absolute;
    top: -16px;
    right: -6px;
  border: none;
  background: white;
  border-radius: 50%;
  padding: 0 0;
  color: #e47100;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.close-modal:hover {
    color: #f88f25;

}

.modalBody{
display: flex;
justify-content: space-between;
margin: 1em 0;
}

.cancelOrder{ 
  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: #331900;
    margin-bottom: 0;
}
.confirm{
  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;

    color: white;
    background: #E47100;
    border-radius: 10px;
border: none;
    padding: 0.3em 2em;
    margin: 0 0.5em;
}
.confirm:hover{
    background: #f17e0b;

}

.cancel{
  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;

    color: #E47100;
    background: white;
    border-radius: 10px;
border: 1px solid #e47100;
    padding: 0.3em 2em;
margin: 0 0.5em;
}
.cancel:hover{
    background: #f17e0b;
    color: white;
}


.modalQuantity p{
    display: inline-block;
    border: 1px solid #C9C9C9;
    padding: 0.7em 1.5em;
    border-style: solid none solid none;
    margin-bottom: 0;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.modalQuantity button{
   
    text-align: center;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    border: 1px solid #C9C9C9;
    border-radius: 10px 0px 0px 10px;
    background-color: transparent;
    padding: 0.7em 1.5em;

}

.modalQuantity .modal-btn2{
    border: 1px solid #C9C9C9;
    border-radius: 0px 10px 10px 0px;
  

}
.modalName{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0;
margin-right: 2em;
max-width: 215px;
}

.modealDesc{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
letter-spacing: -0.3px;
color: #828282;
margin-bottom: 2em;
}
.modalBottom{
    display: flex;
    justify-content: space-between;
}
.modalBottom p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    
    letter-spacing: -0.3px;
 color: #E47100;
    margin-bottom: 0;

}

.modalBottom button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;

    color: white;
    background: #E47100;
    border-radius: 10px;
border: none;
    padding: 0.5em 3em;
    
}
.modalBottom button:hover{
    background: #f68413;

}
  .innerNav{

display: flex;
justify-content: space-between;
align-items: center;
padding-top:0.3em;
padding-bottom:0.3em;
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
letter-spacing: -0.3px;
padding-top: 1em;
color: #4F4F4F;
box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
text-align: center;

  }
  .innerNav p
  {

    margin-bottom: 0;
  }
  .innerNav button{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;
    
    border: 1px solid #E47100;
    color: #E47100;
    background: white;
    border-radius: 10px;

    padding: 0.5em 3em;

    
      }

.innerNav button:hover{ 

    color: white;
    background-color: #e47100;
}
.scrollTitle{

    display: flex;
    justify-content: space-between;
}

.scrollTitle button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.3px;
    border: 1px solid #E47100;
    color: #E47100;
    background: white;
    border-radius: 10px;
    padding: 0.5em 3em;
   
    text-align: center;
  white-space: nowrap;
}
.sideNav{
  
    border: 0.5px solid #BEBEBE;
  border-style: none solid solid none;
overflow-y: auto;
height: 70vh;
width: 270px;

}

.sideNavAr{
  
    border: 0.5px solid #BEBEBE;
  border-style: none none solid solid;
overflow-y: auto;
height: 70vh;
width: 270px;
} 
.reccomendationScroll{
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow: auto;
}
.reccomendationScroll .medyafMenu{
    margin:0 1em;
}

.bodyMargin{
margin-top:80px;
display:flex 
}
.bodyMargin2{
    margin-top:20px;
    display:flex;
  
    }
    .partyLoader{

    position: relative;
    top: 50%;
    transform: translateY(100%);
    }
.products{
overflow-y: auto;
height: 70vh;
}

.productsBook{
    overflow-y: auto;
    height: 78.5vh;
    }
.productsTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
   
    letter-spacing: -0.3px;
    
    color: #633235;
max-width: 400px;


}

.total{
    background: #FFFFFF;
box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
border-radius: 8px;
text-align: center;
padding: 2em;

width: 300px;
}

.totalTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 41px;

letter-spacing: -0.3px;

color: #633235;
margin-bottom: 0;
}
.totalNum{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
 
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
}

.total button{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
background: rgba(228, 113, 0, 0.6);
border-radius: 8px;
border: none;
width: 100%;
box-sizing: unset;
}

.total button:hover{
    background: rgba(241, 125, 9, 0.6);
}

.productItem{
    background: transparent;

text-align: center;
padding: 1em 0;
max-width: 50vw;
width: 50vw;
border-bottom: 1px solid #CDCDCD;
margin: 0;

}
.productItemBook{
    text-align: center;
    padding: 0.5em 1.2em !important;
 
  

    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

}

.productCenter{
  
    justify-content: center;
}
.productItem img{
    border-radius: 15px;
    width: 80px;
    height: 80px;
    margin: 1em 0;
  
}
.active{
    background-color: transparent !important;
}
.productImg{

    padding: 2.5em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 100px;
  
}
.dataRow{
    display: flex;
    justify-content: space-between;
    border-bottom:  0.5px solid rgba(137, 138, 141, 0.44);
    align-items: center;
    padding: 1em 0;
}
.orderTable {

}
table{

    width: 100%;
}
.orderHeader th{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;

letter-spacing: -0.3px;

color: #633235;

}

.orderData{

  
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 8px;

}

.orderData button{
    background: #FEF1E4;
    border-radius: 24px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #E47100;
border: none;
padding: 0.5em 0;

width: 100%;}

.orderData button:hover{
    background: #f5e4d3;
}

.orderData td{
 
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 25px;
padding: 0.8em 0.5em;
letter-spacing: -0.3px;

color: #828282;
}

.th{
    display: flex;
}
.th p{
 margin: 0 0.5em ;
}
.dataRow .title{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
  
    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #4F4F4F;
}
.dataRow .data{
    margin-bottom: 0;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
 
    letter-spacing: -0.3px;
    
    color: #828282;
    
}
.editP{

    color: #828282 !important;
    background-color: transparent !important;
    border: none !important;
    display: inline-block !important;
    width: auto !important;
}
.editP:hover{

    color: #e47100 !important;
   
}
.profileImage{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;

    border-radius: 100px;
    width: 15vw;
    height: 15vw;
    max-width: 200px;
    max-height: 200px;
 
  
}
.profileBorder{
    border: 4px solid #E47100;
    border-radius: 50%;
    padding: 0.3em;
}
.basktTotal{
  
    margin-top: 3em;
    text-align: left;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;

letter-spacing: -0.3px;

color: #4F4F4F;
}
.basktTotal p{


    display: inline-block;
}




.basketImg{
    padding: 3em;
  border-radius: 100px;
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
     
}
.customerSharesImg{
    height: 15em;
  
    width: 15em !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;
    border-radius: 10px;
   
position: static !important;
opacity: 1 !important;
}
.crslBackground2{
    height: 40vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
  
   max-height: 30em;
   
  }

  .crslBackground2Thumbs{
    height: 20vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
  
   max-height: 150px;

  }
  .swiper{
    height: auto !important;
    max-height: 30em;
 
}
.myCarousel .swiper{
    height: auto !important;

}

.swiper-thumbs{
    height: 15vw !important;
    margin-top: 2em;
  
}
.swiper-pagination-progressbar-fill{
border-radius: 20px !important;

}
.carousel .slide img {

    height: 40vw !important;
    min-height: 250px;
    display: none;
}
.carousel{
    text-align: center;
}
.carousel .control-dots .dot {

background: #e47100 !important;
box-shadow: none !important;
}
.carousel .thumb.selected{

    border: 3px solid #e47100 !important;  
}
.carousel .thumb:hover{
    border: 3px solid #e47100 !important; 
}
.dmfLfw{
    overflow: visible !important;
}
.eGeitL{
    overflow: visible !important;
}
.carousel-root {

    direction: ltr;
}
.bookImage{
    height: 15em;
  position: relative;
  z-index: 2;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 10px;
    margin: 1em 0;
}
.menuBckg{
    height: 9em;
  
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;
    border-radius: 10px;
    margin: 1em 0;
margin-top: 0.7em;
}

.productImage{
    border-radius: 15px;
    width: 100% !important;
    
    margin: 1em 0;
  
}
.productName{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;

    letter-spacing: -0.3px;
    
    color: #4F4F4F;
flex-basis: 100%;
margin-bottom: 1em;


}
.productDescription{
  
font-family: 'Cairo';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;

letter-spacing: -0.3px;

color: #4F4F4F;
}
.productItem button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
   
    letter-spacing: -0.3px;
    
    border: none;
    color: white;
    background: #e47100;
    border-radius: 10px;

 padding: 0.5em 1em;
}
.productItem button:hover{
    background-color: #f18416;
}


.productItemBtn{
    font-family: 'Cairo';
    font-style: normal; 
    font-weight: 600;
    font-size: 18px;
    line-height: 20px !important;
    

    letter-spacing: -0.3px;
    
    border: none;
    color: white;
    background: #e47100;
    border-radius:100px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
 padding: 15px 17px !important;
}

.productPrice{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.3px;
    
    
    color: #331900;

}


.bookPrice{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;

    letter-spacing: -0.3px;
    
    color: #4F4F4F;


}

.bookName{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.3px;
    
    flex-basis: 100%;
margin-bottom: 1em;
    color: #331900;

}

.sideLinks{
    display: flex;
    align-content: center;
    align-items: center;
    padding-left: 1em;
    cursor: pointer;
    border-left: 5px solid transparent;
}

.sideLinks img{
width: 60px;
height: 90px;
float: left;
margin:0 10px 0 0;
border-radius: 50%;
padding: 1em 0;
}
.sideLinks p{
    margin-top: 15px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;

color: #331900;
cursor: pointer;
padding: 0.5em 0;

}
.sideLinks:hover{
    background-color: rgba(228, 113, 0, 0.07);
  border-left: 5px solid #e47100;
}

.sideLinksActive{
    background-color: rgba(228, 113, 0, 0.07);
    border-left: 5px solid #e47100;
}

.sideLinksAr{
    display: flex;
    align-content: center;
    align-items: center;
    padding-right: 1em;
    cursor: pointer;
    border-right: 5px solid transparent;
   
}


.sideLinksAr img{
width: 60px;
height: 90px;
float: left;
margin:0 0 0 10px;
border-radius: 50%;
padding: 1em 0;
}
.sideLinksAr p{
    margin-top: 15px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;

color: #331900;
cursor: pointer;
padding: 0.5em 0;

}
.sideLinksAr:hover{
    background-color: rgba(228, 113, 0, 0.07);
  border-right: 5px solid #e47100;
}

.mobileSideLink{

    display:flex;
    flex-direction: row;
    overflow-x: auto;
    margin-bottom: 1em;


}
.mobileSideLink .sideLinks:hover{

    border: none;
   border-bottom: 5px solid #e47100;
    }

    .mobileSideLink .sideLinksAr:hover{

        border: none;
      border-bottom: 5px solid #e47100;
        }
   

.mobileSideLink .sideLinks{

text-align: center;


margin: 1em 0;
margin-right: 1em;
padding: 0 3em;
border-left: none !important;
border-bottom: 5px solid transparent;
}
.mobileSideLink img{
margin: 0;
float: none;
}
.mobileSideLink p{
 margin: 0 0.6em;
 padding: 0;
 line-height: 20px;
 white-space: nowrap;
    }
.mobileSideLink .sideLinksAr{

    margin: 1em 0;
    margin-left: 1em;
    padding: 0 1em;
    border-bottom: 5px solid transparent;
    border-right:none;
    }

 /* Book Menu  */
 .dropdown-check-list {
    display: inline-block;
  }
  
  .dropdown-check-list .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 10px 50px 10px 10px;
    color: #BEBEBE;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    font-family: "Cairo";
    width: 100%;
  }
  
  .dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border-left: 2px solid black;
    border-top: 2px solid black;
    padding: 5px;
    right: 10px;
    top: 30%;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  
  .dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
   
  }
  .dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
  }
  
  .dropdown-check-list ul.items {
    padding: 2px;
    display: none;
    margin: 0;
  
    border: 1px solid #E0E0E0;
    border-radius: 0 0 10px 10px;
    border-top: none;
    padding: 0 1em;
  }
  #list1{
      margin: 1.5em 0;
  }
  
  .dropdown-check-list ul.items li {
      display: flex;
      font-family: 'Cairo';
      align-items: center;
    list-style: none;
  margin: 0.5em 0;
  justify-content: space-between;
  }
  .dropdown-check-list ul.items p{
      margin-bottom: 0;
      color:#E47100
  }
  .dropdown-check-list ul.items span {
  margin: 0 0.5em;
  
  }
  
  .dropdown-check-list.visible .anchor {
    border-radius: 10px 10px 0 0;
    border-bottom: none;
  }
  
  .dropdown-check-list.visible .items {
    display: block;
  }
  
 .bookForm{
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: 100%;

 }
 .centerForm{
     display: flex;
     justify-content: center;
     align-items: center;
 margin-top: 3em;
 margin-bottom: 5em;
 }
 .menuImage{
     width: 100%;
     max-width: 500px;
     border-radius: 10px;
 } 
 .bookingForm{
display: flex;
align-items: center;

 }
 .bookingForm label{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
    
    color: #633235;
    display: block;
 margin-top: 1.5em;
 }
 .paymentLabel{
display: inline-block !important;


 }
 .bookingForm input[type="radio"]{
     display: inline-block;
     width: auto;
    margin-right: 10px;
     accent-color: #e47100;
     cursor: pointer;
 }

 .bookForm textarea{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
 color: #BEBEBE;
 border: 1px solid #E0E0E0;
 border-radius: 10px;
 width: 100%;
 padding: 0.5em;

 }
  textarea:focus{

    border: 2px solid #E47100 ;
 outline: none;

 }

 .bookingAr input[type="radio"]{
    
   margin-left: 10px;
   margin-right: 0 !important;
  
}

 .payment{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
    
    color: #633235;
    display: block;
 margin-top: 1.5em;
 margin-bottom: 0;
 }
 .bookingForm input{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
 color: #BEBEBE;
 border: 1px solid #E0E0E0;
 border-radius: 10px;
 width: 100%;
 padding: 0.5em;
 }
 .paymentMethod{
     display: flex;
     justify-content: space-between;
     margin-bottom: 2em;
 }

 .formBottom{
     display: flex;
     justify-content: space-between;
     padding: 0 2em;
     align-items: center;
     margin-bottom: 2em;
 }
 .formBottom p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 49px;
  text-align: right;
    letter-spacing: -0.3px;
    
    color: #E47100;
    margin-bottom: 0;
 }

 .formBottom button{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;
    
    border:none;
    color: white;
    background: #E47100;
    border-radius: 10px;

    padding:0 3em;
    height: 50px;
 }
 .formBottom button:hover{
    background: #f68d25;}

    /* basket */


    .modalImage{
height: 40vw;
max-height: 260px;

background-size: cover;
background-repeat: none;
background-position: bottom center;
border-radius: 10px;
    }
    .addMore{


        width: auto !important;
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
    
       text-align: center !important;
    
        letter-spacing: -0.3px;
        
        border:none;
        color: white !important;
        background: #E47100 !important;
        border-radius: 10px;
    border-radius: 10px !important;
        padding:0 3em;
     
    }
    ::placeholder {
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
      /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
      }
    .addMore:hover{

        background: #f07e0c !important;

    }
    .orderSuccess{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
 
        letter-spacing: -0.3px;
        color: #e47100;
        text-align: center;

        align-items: center;
    justify-content: center;

    }
    .basketForm span{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 37px;
        color: rgb(193, 22, 22);
        display: block;
    }
.basketSend{
   
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
 
 
    background-color: #E47100;
border-radius: 8px;
width: 50%;
padding: 0.2em 0;
margin-top:2em ;
border: none;
}
.basketSend:hover{
    background-color: #f0851b;

}
.alignQuan{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.alignQuan p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #4F4F4F;
}

    .Basket .center{
 
        display: flex;
     justify-content: center;
     
     }
     
     .Basket img{
         width: 90%;
         height: auto;
         float: right;
         border-radius: 8px;
         margin-left: 5%;
         
            }

            .bTop{
                text-align: center; 
                margin-bottom: 1em;
                margin-top: 1em;
                position: relative;
             }
             
             .bTop button{
             
               border-radius: 50%;
               height: 50px;
               width: 50px;
               border: none;
               background-color: white;
               border-color: white;
              
                }
.basketTop{
    display: flex;
    justify-content: space-between;
    padding: 1em;
    align-items: center;
}
.basketTop p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 25px;

    letter-spacing: -0.3px;
    
    color: #E47100;
    margin-bottom: 0;
}
.basketTop button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
 
    letter-spacing: -0.3px;
    
    color: #E47100;
    border: 1px solid #E47100;
border-radius: 8px;
background-color: white;
padding: 0.5em 1em;
}

.basketTop button:hover{
    color: white;
    background-color: #E47100;

}
.back{
    position: absolute;
    top:0;
    left:2em;
}
.backAr{
    position: absolute;
    top:0;
    right:2em;
}
.backAr p{
    display: inline;
}
.backAr .ciBackbtn{
    right: auto !important;
    left: 2em;
    transform: rotate(0deg);
        transform-origin: 50% 50%;
      
        }

.back p{
   display: inline;
}
                .bTop button:hover{
                   color: #E47100;
                    } 
             .bTop p{
               font-family: 'Cairo';
               font-style: normal;
               font-weight: 700;
               font-size: 28px;
               line-height: 52px;
        
               letter-spacing: -0.3px;
               color: #331900;
             }

             .ciBackbtn{
                right: auto !important;
                left: 2em;
                transform: rotate(180deg);
                    transform-origin: 50% 50%;
                    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
                  margin:0 10px;
                    }
                    .ciImage button:hover{
                        color: #E47100;
                         }

      /*basketItem*/

      .bItem{
        background-color: white;
          
       margin: 1.5em 0;
          }
          .bFlex{
            display: flex;
            justify-content: space-between
        }
 
        .bFlex p{
            margin-bottom: 0.5em;
        }
        .enbName{
  
            font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 25px;

letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0.5em;
max-width: 160px;
           }
           .bDetails{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 31px;
          
            letter-spacing: -0.3px;
            
            color: #4F4F4F;
           }
           .bAlign{
               display: flex;
               flex-direction: column;
         
               justify-content: space-between;
           }
           .removeItem{
               position: absolute;
               right: 0.5em;
               color:  #ACACAC;
               top: 0.5em;
               cursor: pointer;
           }
            .removeItem:hover{
color: #e47100;

            }


           .bItemPrice{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600 !important;
            font-size: 20px !important;
            line-height: 60px;
      
            letter-spacing: -0.3px;
            
            color: #633235 !important;
            margin-bottom: 0;
           }
           .bBtn{
            border: none;
        background: #E0E0E0;
            border-radius: 100px;
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 37px;
           
            text-align: center;
            letter-spacing: -0.3px;
            
            color: #ACACAC;
            padding :0 0.4em;

           }
           .bBtnMinus{
            padding :0 0.15em;   
           }
        
           .bBtn:hover{
 
            background: rgba(228, 113, 0, 0.25);
             color: #f88819;
           }
        .bQuan{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 60px;
       
            letter-spacing: -0.3px;
            
            color: #4F4F4F;
         display: flex;
         align-items: center;
 
         letter-spacing: -0.3px;
         display: inline;
        
         padding: 0 0.8em;
         
        }
        .bPrice{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 37px;
            display: flex;
            align-items: center;
     
            letter-spacing: -0.3px;
         
            color: #331900;
           }
           .bCenter{
         display: flex;
           
            align-items: center; 
          
        }

.bCenter img{
    width: 70%;
    height: 100px;
    float: right;
    border-radius: 8px;
   min-width: 80px;
 
       }
       .bItem button{
           cursor: pointer;
       }
       .basket{
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        position: relative;
    }
    .isEmpty{
        text-align: center;
  
    }
    .isEmpty p{
        font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 32px !important;
            line-height: 37px;
        
     
            letter-spacing: -0.3px;
         
            color: #331900 !important;
       
    }

       .removeItem{
           background: transparent;
           border: none;

       }

    .bDiv2{
     height: 60vh;
     margin-bottom: 5em;
    }

.enbNote{
 
 padding: 1em;
 width: 100%;

 box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
 background: #F4F4F4;
border-radius: 12px;
 margin-top: 50px;
}
.enbNote input{
 font-family: 'Font Awesome 5 Free';

 font-style: normal;
 font-weight: 400;
 font-size: 20px;
 line-height: 41px;

 letter-spacing: -0.3px;

 color: #828282;
 border: none;
border-radius: 10px;
width: 100%;
padding: 3em 1em;
padding-top: 0.5em;
}

input[type="text"]:focus{
 border: 2px solid #E47100 ;
 outline: none;

}
input[type="datetime-local"]:focus{
    border: 2px solid #E47100 !important;
    outline: none;
   
   }
.userProfile a{
    color: unset;
}
.enbNote label{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;

    letter-spacing: -0.3px;
    
    color: #4F4F4F;
 display: block;
 margin-bottom: 0.5em;
 margin-top: 1em;
}
/* topNav*/
.Loginlogo{

    background:#420F12;
border-radius: 0px 0px 20px 20px;
   
    padding: 0.5em 1em;
    max-width: 220px;
    display: flex;
    justify-content: center;
}
.Loginlogo img{

    min-width: 150px;
}

select{
    background:transparent;
    border: none;
 

   
  
   
   
    margin: auto 5px;
  
    cursor: pointer;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;

color: #4F4F4F;
}
.logo{
    background-color: 
    #420F12;
 
    border-radius: 0px 0px 20px 20px;
    display: flex;

}
.logo img{

    padding: 1vw 2vw;
  

}
.nav{
    display: none !important;
}
.newNav{

    margin: 1em 0;

    padding: 1em 0;
}
.newNavFixed{
    margin: 1em 0;
    padding-bottom: 0.5em !important;
    padding: 2em 2.8em;
    position: fixed;
    width: 100%;
    top: -20px;
  left: 0;
    background: white;
    z-index: 11;

}

.navigation {

display: flex;
justify-content: space-around;

background: rgba(228, 113, 0, 0.15);
border-radius: 12px;
padding: 0.5em 0;
}


.navigation p{

    margin-bottom: 0;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #4F4F4F;
    }

    .navigation p:hover{

        color: #DB095B;
    }
    a.active .linksNew {
        color: #DB095B;
    }

.navProfile{
    display: flex;
  align-items: center;
  cursor: pointer;
}
.navProfile:hover .profile{
    color:  #DB095B;
}
.togg{
    background-color: transparent;
    border: none;
    display: none;
    color:#4F4F4F;
    cursor: pointer;

}
.togg:hover{
    color: #DB095B;
}

.nav2Mobile{

    position: absolute;
    display: block;
    background-color: white;
    right: -1em;
    top: 100%;
    transition: all 0.5s ease-out;
    width: 100%;
   
    z-index: 10;
    padding-top: 15px;
    padding-bottom: 10px;
    width: 100vw;
}
.partition{
    font-weight: 200 !important;
    color: white !important;
    font-size: 25px !important;
}

.nav2Mobile .links{
    display: block !important;
      margin-bottom: 0.5rem;
     
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: #4F4F4F;
    padding: 0 30px;
    width: 100%;
    transition: all 0.5s ease;
    border: none !important;
    cursor: pointer;
   
    }
    .activeNav .link{
        color: #e47100;
        border-bottom: 3px solid #e47100;
    }
    .active .navProfile{
        color: #DB095B!important;
      
    }
    .navProfile{
        color: #4F4F4F !important;
      
    }
    .activeNav{
        background-color: #e47100;
     
    }
    .nav2Mobile .links:hover{
       
    }
    .nav{
        display: flex;
       align-content: center;
       height: 100%;
    }

.navLinks{
    margin-bottom: 0;
    font-family: 'Cairo';
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 45px;
 
   color: #4F4F4F;
 
}
.navLinks p{

display: inline-block;
border-bottom: 3px solid transparent;
}
.navLinks p:hover{
    color: #DB095B;
    border-bottom: 3px solid #e47100;
}

.navLinks a{
    color:  #4F4F4F;
}
a.active .links{
    color: #DB095B;
    border-bottom: 3px solid #e47100;  
}


.topNav{
    background-color: white;
display: flex;
justify-content: space-between;

position: sticky;
top: 0;
z-index: 10;

}
.navContent{
  color:  #4F4F4F;
  display: flex;
  align-items: center;
}

.topNav img{

width: 20vw;
max-width: 220px;
min-width: 150px;
}
.navContent p{
  display: inline-block;
  margin-bottom: 0;
  font-family: 'Cairo';
 font-style: normal;
 font-weight: 600;
 font-size: 18px;
 line-height: 15px;
 margin-right: 0.5em;
 text-align: right;
  }
  .navContentAr p{
    text-align: left;
  }
 
  .langBtn:hover{

    color: #e47100;
  }
  .profileIcon{
      cursor: pointer;
    
  }
  .profileIconAr{
    cursor: pointer;
    margin:0 1em;
   margin-left: 0;
}
  .profileIcon:hover{

    color: #e47100;
  }

  @media screen and (max-width: 766px){
      
    .links{
        display: none !important;
       
    }
    .newNav{
        display: none !important;
       
    }
    .newNavFixed{
        display: none !important;
       
    }
    .fluid{
 
        --bs-gutter-x: 15px;
    }
    .togg{
        display: inline;
    }
    
    .profile{
        font-size: 13px !important;
    }
    .nav1{
        margin: 0;
    }

.scrollTitle{
  flex-direction: column;
  align-items: center;
   
}
.goBasket{
    margin: 1em 0;
}
.productsTitle{
    font-size: 20px;
} 
.productName{
    font-size: 15px;
}
.productDescription{
    font-size: 14px;
}
    .NavWhite .togg{
        display: inline; 
    }
    .productsBookHeight{
        height: 48.5vh;
    }
  .contactForm{
      width: 100%;
  }
  .alignCu{
      text-align: center;
  }

.sideLinksAr{

    display: initial;
    text-align: center;
    height: 150px;

}
.sideLinks{

    display: initial;
    text-align: center;
    height: 150px;

}


    .links:hover:before {
        display: none;}
        a.active .links:before {
            display: none;
          }
}
 
  /* footer */


  .footer{
      background-color: #420F12;
   
      padding-top: 2em;
  }
  .footer .container-fluid{

    --bs-gutter-x: 1.5rem !important;
  }

  .footer p{
    color: #fff;
    font-family: Cairo;
    font-size: 16px;
    font-weight: 700;
   
  }
  .footerCenter{
      text-align: center;
  }
  .paymentMethods{
      display: flex;
    align-items: center;
    justify-content: center;
  }
  .paymentMethods img{
    width: 35px;
    margin: 0 0.5em;
}
  .footerEnd{
    text-align: right;
    
}

.footerEndAr{
    text-align: left;
    
}
.footerLogo {
margin: 1em 0;
width: 20vw;
max-width: 200px;
min-width: 170px;
}
.footerCenter img{
    width: 10vw;
    max-width: 200px;
    min-width: 150px; 
}
.copyRights{
    padding: 1.5em 0;
    text-align: center;

}
.copyRights p{
    margin-bottom: 0;
   font-weight: 600;
   font-size: 14px;
}
.vatTitle{

    color: #e47100;
    font-family: 'Cairo';
    font-weight: 700;
    font-size: 22px;
  
  
   
margin: 1.2em 0;
 }

.vat {
    background: #fff;
    max-width: 800px;
    padding: 30px;
    margin: 30px auto;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}
.vat img{
   
    vertical-align: middle;
    border-style: none;
    width: 100%;
}
/* user profile */
.userProfile{
  display: flex;
}

.userProfile span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 37px;
    color: rgb(193, 22, 22);
    display: block;
  }
.userNav{
 display: inline-block;

    background:  #420F12;;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
  
   
    text-align: center;
    padding-top: 2em;

   
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 88px;
 
  
    align-self: flex-start; 
    width: 25vw;
    margin-bottom: 4em;
    border-radius: 0px 10px 10px 0;
}

.upImageBorder img{
    border-radius: 100px;
    width: 20vw;
    height: 20vw;
    max-width: 200px;
    max-height: 200px;
    border: 4px solid #E47100;
    padding: 0.3em;
}
.upImageBorder{
    display: flex;
    justify-content: center;
}


.upName{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 45px;
letter-spacing: -0.3px;

color: #FFFFFF;
}

.upPhone{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: 0.7px;

color: rgba(255, 255, 255, 0.5);
border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
padding-bottom: 2em;
}

.upLinks{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 41px;
text-align: left;
letter-spacing: -0.3px;

color: rgba(255, 255, 255, 0.5);

}
.upLinks p:hover{
   
background: rgba(251, 251, 251, 0.1);
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-left: 10px solid #E47100;

}

.upLinksActive {
    color: #E47100 !important;
  
   
    }

.upLinks p{
padding: 0.5em 1em;
cursor: pointer;
border-left: 10px solid transparent;
}
.upLinksAr p{

    border-right: 10px solid transparent;
}
.profileBody{
   display: flex;
   width: 100%;
}

.upLinksAr{
    text-align: right;
}
.upLinksAr p:hover{
    border-right: 10px solid #E47100;
    border-left: none;
}
.profileDetail{

width: 100%;
text-align: center;
}
.noOrder{

    text-align: center;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;

    letter-spacing: -0.3px;
  color: #e47100;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bookErr{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 37px;
    color: rgb(193, 22, 22);
    display: block; 
margin-left: 2em;
text-align: center;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.mobile-upSideNav{
    padding: 2em;

}
.mobile-upSideNav-top{
    display: flex;
    align-items: center;

}
.mobile-upSideNav img{
  
   width: 130px;
   height: 130px;
  }

  .upInfo{

    margin: 0 1em;
  }
.upInfo-name{
    font-family: 'Cairo';
font-style: normal;
font-weight: 900;
font-size: 32px;
line-height: 60px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0;
}
.upInfo-number{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: 0.7px;
color: #BEBEBE;
}

  .mobile-upSideNave div{
  
   display: inline-block;
   }

  

.mobile-upLinks p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 41px;
letter-spacing: -0.3px;
color: #828282;
display: inline-block;

cursor: pointer;
border-right: 1px solid #BEBEBE;
margin-right: 0.5em;
padding: 0 1em;
}
.mobile-upLinksAr p{
    border-left: 1px solid #BEBEBE;
    border-right: none;
}
.mobile-upLinks p:hover{
    color: #E47100;
 
}

.mobile-upLinksActive{
    color: #E47100 !important;
    border-bottom:  2.5px solid #E47100;
}


.detailDiv{
    background: #FFFFFF;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: 50vw;
   
padding: 2em;
min-width: 350px;
display: inline-block;
}
.detValue{
    display: inline-block;
   
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 41px;
    letter-spacing: -0.3px;
    color:  #828282;
}
.detLabel{
    display: inline-block;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 41px;
    letter-spacing: -0.3px;
    color: #331900;
  
}
.userDetails{
  
 
}

.profileTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 41px;
letter-spacing: -0.3px;
color: #E47100;
}

.contain{
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 3em;
    margin-bottom: 1em;
}
.credit{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 120px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: -0.3px;
color: #331900;

}
.charge{
    background: white;
    border-radius: 10px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;
color: #E47100;
border: 1px solid #E47100;

padding: 0.5em;
width: 20vw;
}
.charge:hover{
    color: white;
    background: #E47100;
}

.logout{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 40px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.3px;
color: #E47100;
margin-bottom: 1em;
}

.logoutContain{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.orderTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;


color: #828282;
}

.orderDiv {
    background: #FFFFFF;
    box-shadow: 4px 5px 30px rgb(0 0 0 / 10%);
    border-radius: 12px;
    width: 68vw;
 
    padding: 2em;
    margin-top: 5em;
    display: inline-block;
}
.alignOrder{
 

}

.medyafOrder{
background-color: white;
box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
border-radius: 18px;
cursor: pointer;


}
.medyafOrder p{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
color: #331900;
}
.orderDate{
    background-color: #E47100;
    display: flex;
    justify-content: space-between;
    align-items: center;
padding: 0.8em;
border-radius: 18px;
}
.orderDate p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: white;  
    margin-bottom: 0;
  
}
.chargeForm input[type="radio"]{
    display: inline-block;
    width: auto;
   margin-right: 10px;
    accent-color: #e47100;
    cursor: pointer;
    
}
.paymentType{
    display: inline-block !important;
    width: auto;
   margin-right: 10px;
  color: #e47100 !important;
  
    
}
.chargeForm input[type="text"]{

    font-family: 'Font Awesome 5 Free';
cursor:text;
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 41px;

 letter-spacing: -0.3px;

 color: #828282;
 border: 1px solid #E0E0E0;
border-radius: 10px;
width: 70%;
padding: 1em 1em;
padding-top: 0.5em;
}
.updateProfile label{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;

    letter-spacing: -0.3px;
    
    
    color: #633235;
display: block;
margin-top: 2em;
margin-bottom: 0.5em;
}
.updateProfile input{

    font-family: 'Font Awesome 5 Free';
    cursor:text;
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 41px;
    
     letter-spacing: -0.3px;
    
     color: #828282;
     border: 1px solid #E0E0E0;
    border-radius: 10px;
    width: 70%;
  padding: 0 1em;

}

.updateProfile button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;
    
    border: 1px solid #E47100;
    color: #E47100;
    background: white;
    border-radius: 10px;

  padding: 0.5em 0;
 display: block;
width: 40%;
display: flex;
justify-content: center;
align-items: center;
}

.updateProfile button:hover{ 

    color: white;
    background-color: #e47100;
}

/*order process*/

.orderDetails{


    background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 8px;
padding: 2em 1em;
}
.orderData{

    margin-bottom: 5em;
}
.tableHover tbody tr:hover td, .tableHover tbody tr:hover th {
    background-color: #FFF7F0;
  }
  .table tr { line-height: 80px !important; }
   td svg{

  }
  .table{
    color:  #707070 !important;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;
text-align: center;
}


  .orderTotal{
      color:  #e47100 !important;
      font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;
text-align: left !important;
  }
  .orderActions{

    display: flex;
    justify-content: end;
 
  }
  .processDown{
      display: none;
  }
.cpTop{
   margin: 3em 1em;
 margin-top: 1em !important;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 0;
}
.cpTop p{
    margin-bottom: 0;
}

.activeStep div{

 background: #FFF7F0 !important;

color: #E47100 !important;
}

.activeStep div:before {

  border-left: 40px solid  #FFF7F0 !important;
}

.activeStep path{
  fill: #E47100 !important;
 stroke:#FFF7F0 ;
    }
    .activeStep ellipse{
       stroke: #e47100;
       fill: #E47100 !important;
          }

.step {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);
    border-radius: 4px;

border-radius: 4px;
z-index: 1;
  }
  .step div{

    margin-left: 2em;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;

color: #C9C9C9;
  }

  .step path{
fill: #C9C9C9 ;

  }
  .step4 path{
    fill: #C9C9C9 ;
    
      }

  .step:after {
    content: "";
    position: absolute;
    left: -5px;
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 50px solid white;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
   
  }
  .step:before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 40px solid  rgba(171, 171, 171, 0.1);;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    
  }

  .step1 {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);

border-radius: 4px;
z-index: 2;
  }
  .step1 svg{
      margin-top: 0.5em;
  }
  .step1 div{

  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: -0.3px;
    
    color: #C9C9C9;
      }
 
  .step1:before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 40px solid  rgba(171, 171, 171, 0.1);
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    
  }

  .step4 {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);
border-radius: 4px;

  }

  .step4 div{

  margin-left: 2em;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: -0.3px;
    
    color: #C9C9C9;
      }
      .step4:after {
        content: "";
        position: absolute;
        left: -5px;
        bottom: -10px;
        width: 0;
        height: 0;
        border-left: 50px solid white;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
       
      }

/* ar */

.step1Ar {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);

border-radius: 4px;
z-index: 2;
transform: rotate(180deg);
transform-origin: 50% 50%;
  }
  .step1Ar div{

  
    transform: rotate(180deg);
    transform-origin: 50% 50%;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: -0.3px;
    
    color: #C9C9C9;
      }
 
  .step1Ar:before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 40px solid  rgba(171, 171, 171, 0.1);
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    
  }


.stepAr {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);
    border-radius: 4px;

border-radius: 4px;
z-index: 1;
transform: rotate(180deg);
transform-origin: 50% 50%;
  }
  .stepAr div{

   
    transform: rotate(180deg);
    transform-origin: 51% 50%;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;

color: #C9C9C9;
  }

.loaderDiv{
   
}

  .stepAr:after {
    content: "";
    position: absolute;
    left: -5px;
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 50px solid white;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
   
  }
  .stepAr:before {
    content: "";
    position: absolute;
    right: -40px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-left: 40px solid  rgba(171, 171, 171, 0.1);;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    
  }


  .step4Ar {
    width: 200.5px;
    height: 80px;
    position: relative;
    background: rgba(171, 171, 171, 0.1);
border-radius: 4px;
transform: rotate(180deg);
transform-origin: 50% 50%;
  }

  .step4Ar div{
    transform: rotate(180deg);
    transform-origin: 55% 50%;
  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: -0.3px;
    
    color: #C9C9C9;
      }
      .step4Ar:after {
        content: "";
        position: absolute;
        left: -5px;
        bottom: -10px;
        width: 0;
        height: 0;
        border-left: 50px solid white;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
       
      }

/*login*/
.login{

    background: #F4F4F4;
border-radius: 12px;
padding: 2em;
overflow: hidden;
}

.loginContainer{
  
    display: flex;
    justify-content: center;
    align-items: center;
   
    height: 100vh;
}
.loginRegContainer{
height: auto !important;
margin: 6em 0;

}
.eye{
position: absolute;
right: 10px;
top: 8px;
cursor: pointer;

}
.loginTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 45px;
letter-spacing: -0.3px;
text-align: center;
color: #420F12;

}
.loginForm label{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 41px;
 
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    display: block;
margin-top: 1em;
}

.loginForm input{

    background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #ACACAC;
border: none;
padding: 0.5em 10px;
width: 80%;
}
.loginForm select{

    background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #ACACAC;
border: none;
padding: 0.5em 10px;
width: 80%;
}

select:focus{

    border: 2px solid #E47100 !important;
    outline: none !important;
}
input:focus{

    border: 2px solid #E47100 !important;
    outline: none !important;
}
.loginForm .forgot{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 37px;

letter-spacing: -0.3px;
text-decoration-line: underline;

color: #4F4F4F;
cursor: pointer;

position: absolute;
right: 0;
top: 2.8em;
}
.loginForm .forgot:hover{
color: #E47100 ;

}
.calendarIcon{
    position: absolute;
    top: 10px;
    color: #ACACAC;
}

.loginForm  .react-datepicker-wrapper input{
    width: 100% !important;
   
  }
  .loginForm .react-datepicker button{
    margin-top: 10px !important;
  }
  .react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--selected-year{
    color:  black;
  }
  .react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--down-arrow{
  
  top: 35% !important;
  }
  .userData .inputDiv .react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--down-arrow{
    top: 20% !important;
  
  }
  .react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    content: "\25B2";
    position: absolute;
    top: 0%;
    left: 45%;
    margin: auto;
  }
  
   .react-datepicker__year-option--selected_year{
  font-weight: bold !important;
  }
  .react-datepicker__year-option--selected{
    display: none !important;
  }
  .react-datepicker__year-dropdown::after {
    content: "\25BC";
    display: block;
    position: relative;
    bottom: 0%;
  }
  
.loginForm button[type="submit"]{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
 
    letter-spacing: -0.3px;
    padding: 0.3em 10px;
width: 80%;
    background-color: #e47100;
color: white;
border: none;
border-radius: 8px;
}
.loginForm button[type="submit"]:hover{
    background-color: #f28211;
 
}

.register{
    margin-top: 1em;
}

.register p{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 37px;
  
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
margin-bottom: 0.2em;
}


.register button{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
 
    letter-spacing: -0.3px;
    padding: 0.3em 10px;
width: 80%;
border: 1px solid #E47100;
    background-color: white;
color: #E47100;

border-radius: 8px;

}

.register button:hover{
    color: white;
    background-color: #E47100;

}
.loginImages{
    position: relative;
}

.loginImages .img1{

position: absolute;
width: 300px;
transform: rotate(180deg);
right:-22px;
top:-25px;
}
.loginImages .img2{

    position: absolute;
    width: 480px;
    right: 70px;
    }
    .loginImages .img3{

        position: absolute;
        width: 115px;
        right: 30px;
        top: 80px;
        }


        .loginImagesAr{
            position: relative;
        }
        
        .loginImagesAr .img1{
    
            position: absolute;
            width: 300px;
           
            left:-21px;
            top:-72px;
            }
            .loginImagesAr .img2{
            
                position: absolute;
                width: 480px;
                left: -90px;
                }
                .loginImagesAr .img3{
            
                    position: absolute;
                    width: 115px;
                    left: 11px;
                    top: 61px;
                    }

        /*forgetPass*/

.fpLogo{
    
    background: rgba(228, 113, 0, 0.1);
    border: 10px solid #F2E7DC;
    border-radius: 28px;

color: #E47100;
margin-bottom: 1em;
}
.fp-p1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 30px;
letter-spacing: -0.3px;
margin-bottom: 0;
color: #4F4F4F;
}
.fp-p2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
  
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
}
.fpForm{
    display: flex;
    justify-content: center;
}
.fpBody{
position: relative;

width: 70%;
}
.fpFormLabel{
    text-align: left;
}

.fpForm input{
    width: 100%;

}
.fpForm button{
  
    width: 100%;
  margin: 2em 0;
}
.verifyCode input{
    width: 44px;
    height: 44px;
margin-right: 1em;
padding-left: 18px;
}
.loginForm span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 37px;
    color: rgb(193, 22, 22);
    display: block;
}
.invalid {

    border: 2px solid rgb(193, 22, 22) !important;
}

.invalid:focus {

    border: 2px solid rgb(193, 22, 22) !important;
    outline: none !important;
}

.forgotInvalid{

    top:5em !important;
}

input[type="checkbox"]{
    accent-color: #e47100;
    width: auto;
    cursor: pointer;
}

.terms{
   display: inline-block !important;
   margin:0 1em;
   color: #e47100 !important;
   font-size: 20px !important;
   cursor: pointer;
}

.resend{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 37px;
  
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    border-bottom: 1px solid #4F4F4F;
    display: inline-block;
    cursor: pointer;
   
}

.resend:hover{
    border-bottom: 1px solid  #e47100;
    color: #e47100;
}
.notFound{
    height: 44vw;
    padding-bottom: 8vw !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    border-radius: 10px;
    align-items: flex-end;
    justify-content: center;
    }
    .notFound p{
    color: white;
    font-size: 5vw;
    }
.cancelBtn button{
margin: 1em 0;
    background: #E47100;
border-radius: 10px  0 0 10px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;
color: #FFFFFF;
border: none;

padding: 0.5em;
box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}
.cancelBtnAr button{
    border-radius: 0  10px 10px 0;

}

.payBtn{
    border-radius: 0px  10px 10px 0 !important; 

}
.payBtn{
    border-radius: 10px !important; 
    background-color: white !important;
    color: #E47100 !important;
    border: 1px solid #E47100 !important;
    border-radius: 0 10px 10px  0 !important;
}
.payBtnAr{
    border-radius: 10px !important; 
    background-color: white !important;
    color: #E47100 !important;
    border: 1px solid #E47100 !important;
    border-radius: 10px 0 0  10px !important;
}
.cnclBtn{
    border-radius: 10px !important; 

}
.cancelBtn button{
    border: 1px solid #E47100;
}

.cancelBtn button:hover{

    background: #f6891b;
}
ol{
    position: relative;
    right: -39px;
}


.tcBody{
    flex-direction: initial !important;
}

/*mobile*/

@media screen and (max-width: 476px){
   
.mobileTogg{

    margin: 0 !important;
}
.togg{
    margin: 0 !important;
}
.navContent p {
    margin: 0 10px !important; 
}
}



@media screen and (max-width: 391px){
   
    .footerEnd{
    text-align: center !important;
    }
    .footerEndAr{
        text-align: center !important;
    }
 
    }

    @media screen and (max-width: 950px){
   
        .normalCategory2{
            display: none;
        }}

        @media screen and (max-width: 850px){
   
            .normalCategory{
                display: none;
            }
            .menuMealDiv{
                margin-top: 1.5em !important;
            }
        }
    

        @media screen and (min-width: 951px){
   
            .topCategories2{
                display: none;
            }
        
        }
      


    @media screen and (max-width: 735px){
      
   
      .normalCategory{
           display: none; 
    }
    .bodyMargin{
        margin-top:3em;
    }
    .productsTitle{
        margin-bottom: 0;
    }
 

        }

        @media screen and (max-width:516px){
      
            .productImg{

                padding: 1.5em;
             
                }
                .colFlexBtn{
                    justify-content: center;
                }}

                @media screen and (max-width:740px){
      
                   

                    .innerNav1{
                    text-align: center;
                    }
                    .innerNav1 p{
                        font-size: 16px;
                        line-height: 20px;
                      
                    }
                    .innerNav1 button{
                        font-size: 16px;
                        line-height: 20px;
                
                            padding: 0.2em 1em;
                     }
                     .innerNav{
                 
                    }
                    .innerNav p{
                       font-size: 16px;
                       line-height: 20px;
                     
                     }
                     .innerNav button{
                       padding: -1.8em 1em;
                       font-size: 16px;
                       line-height: 20px;
                     

                      }}
        @media screen and (max-width: 922px){
   
.loginContainer{
    text-align: center;
}
.passAlign{ 

    display: inline-block;
}
            .loginImages{
                display: none;
            }
            .loginImagesAr{
                display: none;

            }
            .login{
                padding: 1em;


            }
            .loginContainer{


              
                padding-top: 5em;
                height: auto;
            }
            .loginRegContainer{

margin-top: 0;
            }
        }

        @media screen and (min-width: 851px){
   
            .topCategories{
                display: none;
            }
        
          
        }

        @media screen and (max-width: 563px){
   
            .modal-content{
            
               width: 90vw;
            }
        
            }


        @media screen and (max-width: 700px){
   
          
            .modalBody{
               flex-direction: column-reverse;
               margin-bottom: 0;
            }
            
          
        .modalBottom button{
padding: 0.5em 1em;

        }
            }


       
            @media screen and (max-width: 1065px){
   
          
                .userNav{
                  display: none;
                }
                .userProfile{
                    flex-direction: column 
                }
                .orderDiv{
                    width: 90vw;
                    margin-top: 2em;
                }
           
                }
                @media screen and (min-width: 1066px){
                 
          
                    .mobile-upSideNav{
                      display: none;
                    }
                 .profileDetail{
                     display: flex;
                     justify-content: center;
                     align-items: center;
                 }
               
                    }

                    @media screen and (max-width: 991px){
                 
          
                        .menuImageDiv{
                         text-align: center;
                        }
                     /*   .paymentMethod{
justify-content:inherit;
                        }
                        .paymentMobile{
                            margin: 0 5em;
                        }*/
                   
                        }

                        @media screen and (max-width: 575px){
                            .socials svg{
                                width: 15px;
                                height: 15px;
                            }
                            .socials a{
                                margin-bottom: 10px;
                            }
                            .productItem span{
                                display: none;
                                border-radius: 50px;
                            }
                            .productItem button{
                             
                                border-radius: 50px;
                                padding: 1em 1em;
                            }
                        }

                        @media screen and (max-width: 585px){
   
                            .step div{
                             font-size: 16px;
                             line-height: 15px;
                             margin-top: 5%;
                            }
                            .step1 div{
                                font-size: 16px;
                                line-height: 15px;
                                margin-top: 10%;
                               }
                               .step4 div{
                                font-size: 16px;
                                line-height: 15px;
                                margin-top: 5%;
                               }
                               .cpTop svg{
                                   margin-bottom: 0.5em !important;
                               }
                         
                        }

                        @media screen and (max-width: 538px){
   
                            .mobileTogg{
                            
                             margin: 0 !important;
                            }
                          .togg{
                              margin: 0 !important;
                          }
                          .navContent p{
                              font-size: 18px;
                          }
                          .profileIconAr{
margin: 0 !important;
                          }
                          .profileIcon{
                            margin: 0 !important;
                        }
                        .topNav img{
                            min-width: 113px !important;
                            padding: 2vw 2vw;
                        }
                      
                         .topNavLogin{
                            justify-content: space-between;
                         }
                        }

                        @media screen and (max-width: 426px){
   
                           .topNav {
                              
                            }
                          
                         
                        }
                        @media screen and (max-width: 467px){
   
                            .step div{
                            
                             margin-top: auto;
                            }
                          
                         
                        }

                        @media screen and (max-width: 766px){
   
                            .copyRights{
                                display: block;
                                text-align: center;
                            }

                            .basketTop{
                            
                             margin-top: 1em;
                            }
                          
                         .basketTop button{

                            font-size: 18px;
                            padding: 0.3em 0.5em;
                            line-height: 22px;
                         }
                         .basketTop p{
                            font-size: 16px;
                            max-width: 240px;
                         }

                        }
                        @media screen and (max-width: 474px){
   
                           
                         .basketTop p{
                           
                            max-width: 140px;
                         }

                        }


                        @media screen and (max-width: 575px){
   
                            .productCenter{
                            
                           display: flex;
                          
                            }
                          .productItemBook{
width: 90%;

                          }
                         .menuBckg{

height: 38vw;
                         }
                        }

                      

                        @media screen and (max-width: 575px){
   
                            .footerEnd{
                            
                            text-align: center;
                            }
                          .footerVat{
                            text-align: center; 
                          }

                         .footerEndAr{
                            text-align: center;  
                         }
                        }
                        @media screen and (max-width: 991px){
                        .zoom:hover {
                            transform: scale(1.5);
                        transform-origin: 8em 50%;
                        background-size: contain;
                        }
                        .zoomAr:hover {
                        
                            transform-origin:75% 50% ;
                          
                            }
                   .bookImage{
height: 20em;
width: 80%;

                   }
                    }
                    @media screen and (max-width: 766px){
                        .zoom:hover {
                            transform: scale(1.5);
                        transform-origin: 6.5em 0;
                        background-size: contain;
                        }
                        .zoomAr:hover {
                        
                        transform-origin: 75% 0 ;
                      
                        }
                   .bookImage{
height: 40vw;
width: 80%;

                   }
                   
                    }

                    @media screen and (max-width: 544px){
                       
                    .alignBookTop{
                        text-align: center;
                    }
                    .bookName{
margin-bottom: 0 !important;

                    }
                    .bookImage{
                        display: inline-block;
                        width: 100%;
                    }
                    .zoom:hover {
                        transform: scale(1.5);
                    transform-origin: 6.5em 0;
                    background-size: contain;
                    }
                    .zoomAr:hover {
                    
                    transform-origin: 50% 0 ;
                  
                    }

                    .orderDiv{
padding: 1em;
text-align: center;
                    }
                    .orderData button{

                        font-size: 14px;
    line-height: 15px;
    padding: 0.5em 0.5em;
                    }
                   
                    }



                    @media screen and (max-width: 767px){
                        .notFound{
                            padding-bottom: 5vw !important;
                        }
                    }
                        @media screen and (max-width: 665px){
   
                            .cpTop{
                                align-items: center;
                                flex-direction: column;
                              
                            }

                          .processDown{
                              display: block;
                              margin: 1em 0;
                              color: #C9C9C9;
                            
                          }  
                            .step div{
                           margin: 0 !important;
                               }
                               .step4 div{
                                margin: 0 !important;
                                    }
                            
                            .step::before{
                            display: none;
                            }
                            .step1::before{
                                display: none;
                               }
                               .step::after{
                                display: none;
                               }
                               .step4::before{
                                display: none;
                               }
                               .step4::after{
                                display: none;
                               }




                          
                            .stepAr div{
                          transform-origin: 50% 50%;
                               }
                               .step4Ar div{
                                transform-origin: 50% 50%;
                                    }

                                    .step1Ar div{
                                        transform-origin: 50% 50%;
                                            }
                                    
                            
                            .stepAr::before{
                            display: none;
                            }
                            .step1Ar::before{
                                display: none;
                               }
                               .stepAr::after{
                                display: none;
                               }
                               .step4Ar::before{
                                display: none;
                               }
                               .step4Ar::after{
                                display: none;
                               }
                         
                        }



                        @media screen and (max-width:1065px){

                            .orderLoader{
                                height: 50vh;
                                display: flex;
                             transform: none;
                              
justify-content: center;
align-items: center;
                            }
                            .orderLoader span{
                                transform: rotate(90deg);
                            }

                            }
                            @media screen and (max-width:766px){

                            .notFoundCountainer{
                                margin-top: 1em;
                                margin-bottom: 2em;
                                min-height:calc(100vh - 444px);
                                transform: translateY(18%);
                            
                            
                            }
                            .flexBetween{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                min-height:calc(100vh - 44px);

                            }
                        }
                        @media screen and (max-width: 500px){
                        .notFoundCountainer{
                            transform: translateY(25%);

                        }
                            .flexBetween{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                min-height:calc(100vh - 40px);

                            }

                            .notFound{
                                padding-bottom: 2.5vw !important;
                            }
                            #list1{
                                margin-top: 0.5em;
                            }
                            .dropdown-check-list .anchor{
                                font-size: 14px;
                            }
                            .dropdown-check-list span{
                                font-size: 13px;
                            }
                            .hospitableInfo h1{
                                font-size: 16px;
                                margin-top: 20px;
                            }
                          .col-lg-4{
margin-top: 30px;
                            }
.hospitableInfo p{
    font-size: 14px;
    margin-bottom: 50px;
}
.medyafMenu p{
    font-size: 14px;
}
.medyafDetails p{
    font-size: 13px;
}
.medyafMenu button{
    font-size: 14px;
}

.otherMenusAr .other{

    font-size: 16px;
}
.productName{
    font-size: 14px;
}
.productDescription{
    font-size: 13px;
}
.mobileSideLink p{
    font-size: 14px;
}
.productsTitle{
    font-size: 14px;  
}
.innerNav1 p{
    font-size: 13px;  
}
.innerNav1 button{
    font-size: 14px;  
}
.bookName{
    font-size: 16px;
}

.bookPrice{
    font-size: 14px; 
}

.bookingForm label{
    font-size: 14px; 
}
.bookingForm input{
    font-size: 13px; 
}
.bookingForm input::placeholder{
    font-size: 13px; 
}
.formBottom p{
    font-size: 14px; 
    line-height: normal;
}
.formBottom button{
 
        font-size: 14px; 
}
.formBottom{
    margin-top: 1em;
}
.alignBookTop{
padding: 2em !important;

}    
select{
    font-size: 14px;
}
.nav2Mobile .links{
    font-size: 13px;   
}
.servicesTitle{

    font-size: 16px;
    margin-bottom: 3em;
}
.serviceName{
    font-size: 14px;
}

.details p{
    font-size: 13px;
}
.col-md-4{
margin-top: 20px;
}
.cuHeader{
    font-size: 14px;
    margin-bottom: 2em;
    line-height: normal;
}
.cuTitles{
 
    font-size: 14px; 
    line-height: normal;
}
.contactForm{
    margin-bottom: 0;
}
.contactForm label{
    font-size: 14px; 
}
.contactForm input{
    font-size: 13px; 
}
.contactForm input::placeholder{
    font-size: 14px; 
}
.contactForm button{
    font-size: 14px; 
}

.contactPhone p{
    font-size: 14px;  
}

.cuSocials button{
    width: 40px;
    height: 40px;
}
.contactPhone div{
    margin-top: 1em !important;
}
.contactPhone button{
    width: 35px;
    height: 35px; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactPhone button img{
    width: 20px;
    height: 20px;
    position: relative;
   
    
}
.cuSocials button img{
    width: 25px;
    height: 25px;
}
.cuSocials{
    margin-top: 2em;
}
.upInfo-name{
    font-size: 16px;
    line-height: normal;
}
.upInfo{
    position: relative;
    top: 10px;
  
}
.cancelOrder{
    font-size: 16px;
}
.cancel{
    font-size: 14px;
}
.confirm{
    font-size: 14px;
}
.contactForm{
    padding-top: 0.5em;
}
.upInfo-number{
    font-size: 14px; 
}
.mobile-upLinksAr p{
    font-size: 14px; 
}
.profileTitle{
    font-size: 14px; 
    line-height: normal;
    margin-bottom: 0;
    
}
.detLabel{
    font-size: 13px; 
}
.detValue{
    font-size: 13px;    
}
.contain{
    margin-top: 2em;
    margin-bottom: 0;
}
.logout{
    font-size: 16px;   
}
.charge{
    font-size: 14px;  
    padding: 0 0.5em; 
}
.logoutContain img{
    width: 60px;
    height: auto;
}
.orderHeader th{
    font-size: 14px;  
    line-height: normal; 
}
.orderData td{
    font-size: 13px;   
}
.mobile-upSideNav{
padding-bottom: 0;
}
.detailDiv{
    margin-top: 2em;
}
.orderDiv{
    margin-top: 0.5em;
}
.dataRow .title{
    font-size: 14px; 
}
.dataRow .data{
    font-size: 13px;  
}
.servicesTitle{
    font-size: 15px;
    margin-bottom: 1.5em; 
}
.cancelBtn button{
    font-size: 14px; 
}
.cpTop p{
    font-size: 14px;   
}
.cpTop svg{
    margin-bottom: 0 !important;
}
.cpTop{
    margin-bottom: 2em;
}
.scrollTitle button{
    font-size: 14px;    
}
.productItem button{
    width: 40px;
    height: 40px;
    padding: 0 0.6em !important;
   
}
.isEmpty p{
    font-size: 16px !important;    
    margin-bottom: 0;
}  
.shareForm button{
    font-size: 14px; 
    margin-top: 20px;  
}
.isEmpty{
    padding: 1em;
}
.modalName{
    font-size: 14px;
}
.modealDesc{
    font-size: 13px;
} 
.modalBottom p{
    font-size: 13px;  
}
.modalBottom button{
    font-size: 14px;  
}
.modalQuantity button{
    padding: 0.4em 1em;
}
.modalQuantity p{
    padding: 0.4em 1em; 
}
.modal-content{
    height: 390px;
}
.addedLogo{
    margin-bottom: 0;
    width: 100px !important;
}
.modalBody{
    margin-top: 0;
}
.modalQuantity{
margin-top: 1em;
}
.basketTop button{
    font-size: 14px;
}
.basketTop p{
    font-size: 14px;
    line-height: 20px;
}
.enbName{
    font-size: 14px;
}
.bDetails{
    font-size: 13px; 
}
.bQuan{
    position: relative;
    bottom: 4px;
}
.alignQuan p{
    font-size: 14px; 
  
    margin-bottom: 0;
    
}
.bItemPrice{
    font-size: 13px !important;  
}
.bBtn{
    padding:   0;
    width: 30px;
height: 30px;

}
.removeItem{
    width: 25px;
    height: 25px; 
}
.bBtn svg{
width: 15px;
height: 15px;
position: relative;
bottom: 4px;
}
.basktTotal p{
    font-size: 16px;
}
.bBtnMinus svg{
    width: 27px;
    height: 27px;}

    .basktTotal{
margin-top: 2em;

    }
    .enbNote label{
font-size: 14px;
    }
    .enbNote input{
        font-size: 13px;
    }
    .enbNote input::placeholder{
        font-size: 13px;
    }
    .basketSend{
        font-size: 14px;  
    }
   
    .basketTop{
        padding: 0;
    }

    .enbNote{
margin-top: 2em;
    }
    .orderSuccess{
        font-size: 16px;
        line-height: normal;
    }
    .orderSuccess div{
        position: relative;
        top: 20%;
    }
    .shareForm {
        top: 0 !important;
    }
    .loginTitle p{
        margin-bottom: 0;
    }
    


  
    .loginForm label {
        margin-top: 0.5em;

    }
    .bookingForm label{
        margin-top: 0.5em;
    }
   
    .loginTitle{
        font-size: 16px;
    }
    .loginForm label{
        font-size: 14px !important;
    }
    .loginForm input{
        font-size: 13px;
    }
    .loginForm select{
        font-size: 13px;
    }
    .loginForm input::placeholder{
        font-size: 13px;
    }
    .forgot{
        font-size: 13px !important;
    }
    .loginForm span{
        font-size: 12px;
    }
    .register p{
        font-size: 14px;
    }
    .loginForm button{
        font-size: 14px;
    }
    .register button{
        font-size: 14px;  
    }
  
    .invalidSpan{
        font-size: 12px;
       
    }
    .basketForm span{
        font-size: 12px;
    }
    .vatTitle{
        font-size: 16px;  
    }
    .fp-p1{
        font-size: 16px;  
    }
    .resend{
        font-size: 13px;  
    }
    .togg svg{
        width: 30px;
        height: 30px;
    }
    .bookErr{
        font-size: 12px;  
    }
    .mobile-upLinks p{
        font-size: 14px;  
    }
    .other{
        font-size: 16px !important;  
    }
    .innerNav1{
flex-direction: column;
justify-content: end;
align-items: start;
    }

    .bookDiv{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .productsBookHeight{
        height: 39.5vh;
    }
    .alignBookTop{
padding-bottom: 0 !important;
    }
    .productItem{
padding: 0;
    }
    .basketEmpty{
        position: relative;
        top: 60px;
        height: 39vh;
    }
    .enbNote{
        margin-top: 1em;
    }
    .basketTop p{
        max-width:200px
    }
    .contactForm label{
        margin-top: 0.5em;
    }
    .enbNote label{
        line-height: normal;
    }
    .cpTop{
margin-top: 0 !important;
    }
   
}  



@media screen and (min-width: 1700px){
    .hospitableInfo h1{
        font-size: 1.6vw;
    }

    .hospitableInfo p {
        font-size: 1.3vw;
}

.medyafMenu .bckg{
 height:13vw;
}

.medyafMenu p{
    font-size: 1.4vw;
}
.medyafDetails p{
    font-size: 1.2vw;
}
.mmIconsAr2{
width:1.7vw;
height: 1.7vw;
}
.mmIcon2{
    width:1.7vw;
height: 1.7vw;
}
.mmIcon{
    width:1.7vw;
height: 1.7vw;
}
.mmIconsAr{
    width: 1.7vw;
    height: 1.7vw;
    }
.medyafMenu button{
    font-size: 1.4vw;
}
.copyRights p{
    font-size: 1.3vw;

}
.paymentMethods img{
    width: 2.7vw;
}
.footerLogo{
    max-width: unset;
    width: 17vw;
}
.footerImgs img{
    width: 4vw;
}
.navigation p{
    font-size: 1.3vw;

}
.navContent p{
    font-size: 1.4vw;

}
select{
    font-size: 1.3vw;

}
.profileIcon{
    width: 1.7vw;
    height: 1.7vw;
}
.profileIconAr{
    width: 1.7vw;
    height: 1.7vw;
}
.navigation{
padding: 1em 0;
}
.innerNav1 p{
    font-size: 1.5vw;

}
.innerNav1 button{
    font-size: 1.4vw;

}
.productsTitle{
    
    font-size: 1.7vw;

}
.sideLinks p{
    font-size: 1.4vw;
}
.sideLinks img{
  width:   3.5vw;
    height: 5vw;
}
.sideLinks{

    border-width: 10px !important;
}
.productName{
    font-size: 1.4vw;
    margin-bottom: 0.5em !important;
}
.productDescription{
    font-size: 1.3vw;
    line-height: 1.5vw;
}
.otherMenus .other{
    font-size: 2.2vw;
}
.menuBckg{
    height: 10vw;
}
.bookName{
    font-size: 1.4vw;

}
.bookPrice{
    font-size: 1.4vw;

}
.formBottom p{
    font-size: 1.7vw;

}
.bookImage{
    height: 20vw;
}
.bookingForm label{
    font-size: 1.5vw;
    margin-bottom: 0.5em;
}
.bookingForm input{
    font-size: 1.5vw;
}
.bookingForm span{
    font-size: 1.5vw;
}
.bookingForm input::placeholder{
    font-size: 1.5vw;

}
.dropdown-check-list .anchor{
    font-size: 1.4vw;

}
.formBottom button{
    font-size: 1.4vw;

}
.servicesTitle{
    font-size: 1.6vw;
}
.serviceName{
    font-size: 1.5vw;

}
.details p{
    font-size: 1.3vw;
}
.serviceImage{
  height:  13vw
}
.crslBackground2{
    max-height: unset;
    height:30vw
}
.swiper{
    max-height: unset;
}
.cuHeader{
    font-size: 1.8vw;

}
.cuTitles{
    font-size: 1.9vw;

}
.contactPhone p{
    font-size: 1.7vw;

}
.contactForm button{
    font-size: 1.4vw;

}
.contactForm label{
    font-size: 1.6vw;
}
.contactForm input{
    font-size: 1.3vw;
}
.contactForm input::placeholder{
    font-size: 1.3vw;
}
.contactPhone img{
width: 1.7vw;
height: 1.7vw;

}
.cuSocials img{
    width: 3vw;
    height: 3vw; 
}
.cuSocials button{
    width: 4vw;
    height: 4vw; 
}
.twitter img{
    top: 0.3vw;
}
.cancelOrder{
    font-size: 1.8vw;
 
}
.contactSuccess{
height: 9vw !important;
}
.topNav img{
    max-width: 15vw;
}
.profileTitle{
    font-size: 1.6vw;
}
.detLabel{
    font-size: 1.6vw;
}
.detValue{
    font-size: 1.6vw;
}
.upName{
    
    font-size: 1.8vw;
}
.upPhone{
    
    font-size: 1.5vw;
}
.upLinks{
    
    font-size: 1.6vw;
}
.logout{
  
    font-size: 2.2vw;
    margin-top: 0.5em;

}
.charge{
    font-size: 1.4vw;
}
.logoutContain img{
width: 9vw;
}
.vatTitle{
    font-size: 1.6vw;

}
.orderHeader th{
    font-size: 1.4vw;
}
.orderData td{
    font-size: 1.3vw;

}
.orderData button{
    font-size: 1.3vw;

}
.cpTop{
    transform: scale(1.5);
}
.dataRow .title{
    font-size: 1.4vw;
 
}
.orderProcess{
    overflow: hidden;
}
.dataRow .data{
    font-size: 1.4vw;

}
.dataRow img{
    width: 1.7vw;
    height: 1.7vw;
}
.orderActions button{
    font-size: 1.4vw;

}
.modalName{
    font-size: 1.8vw;
}
.modealDesc{
    font-size: 1.5vw;
}
.modalBottom p{
    font-size: 2.1vw;
}
.modalBottom button{
    font-size: 1.4vw;
}
.modalQuantity button{
    font-size: 1.4vw;
}
.modalQuantity p{
    font-size: 1.4vw;
}
.addItem{
    width: 40vw;
    height: unset;
}
.addItem .modalImage{
height: 20vw;
max-height: unset;
background-position: center;
}
.addItem .vCenter{
    transform: none;
}
.close-modal svg{
    width: 2.7vw;
    height: 2.7vw; 
}
.orderSuccess{
    min-height: 50vh;
}
.isEmpty p{
    font-size: 2.2vw;
}
.shareForm button{
    
    font-size: 1.4vw;
}
.shareForm img{
    width: 8vw;
    height: 8vw;
}
.basketTop p{
    font-size: 1.7vw;
}
.basketTop button{
    font-size: 1.5vw;
}
.enbNote label{
    font-size: 1.7vw;
}
.enbNote input{
    font-size: 1.5vw;
}
.enbNote input::placeholder{
    font-size: 1.5vw;
}
.basktTotal{
    font-size: 1.7vw;
}
.basketSend{
    font-size: 1.5vw;
    padding: 0.5em 0;
    max-width: 400px;
}
.enbName{
    font-size: 1.5vw;
    max-width:300px
}
.bDetails{
    font-size: 1.4vw;
}
.alignQuan p{
    font-size: 1.5vw;

}
.bItemPrice{
    font-size: 1.5vw !important;
}
.bItem button{
    font-size: 1.5vw;
}

.bItem svg{
    height: 1.4vw;
    width: 1.4vw;
}
.bBtn{
padding: 0 !important;
height: 2vw ;
width: 2vw ;


}
.bBtn svg{
    position: relative;
    bottom: 0.1vw;
}
.removeItem{
    height: 1.7vw ;
    width: 1.7vw ; 
}
 .bBtnMinus svg{
    height: 1.5vw !important;
    width: 1.5vw !important;
}
.Loginlogo{
    max-width: unset;
}
.loginTitle{
    
    font-size: 1.7vw;
}
.loginForm label{
    font-size: 1.6vw !important;
    margin-bottom: 0.5em;
}
input[type="checkbox"]{
    height: 1.3vw ;
    width: 1.3vw ;  
}
.loginForm input{
    font-size: 1.3vw !important;
}
.loginForm input::placeholder{
    font-size: 1.3vw !important;
}
.eye{
width: 2vw;
height: 2vw;
}
.fpLogo{
    width: 4vw;
    height: 4vw;  
    border-radius: 50%; 
}
.fp-p1{
    font-size: 1.7vw;
    line-height: 1.8vw;
}
.resend{
    font-size: 1.3vw;
}
.loginForm .forgot{
    font-size: 1.3vw;
}
.loginForm button{
    font-size: 1.5vw;
    padding: 0.5em 0;
}
.register p{
    font-size: 1.5vw;
    margin-bottom: 0.5em;
}
.login{
padding: 3vw 2em;

}
.loginContainer{
height: 90vh;
}
.register button{
    font-size: 1.5vw;
}
.loginForm select{
    font-size: 1.3vw;
}
.loginForm span{
    font-size: 1.3vw;
 
}

}